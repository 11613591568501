<template>
    <v-container fluid>
      <v-dialog v-model="value" persistent max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline">Restablecer contraseña</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <validation-observer ref="formResetPassword">
                <v-row>
                  <v-col cols="12" sm="12">
                      <validation-provider :rules="{ required: true,min:8,confirmed:'confirmation', is_not:curp }" v-slot="{errors}">
                        <v-text-field
                        :error-messages="errors[0]"
                        v-model="passwordNuevo"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-2"
                        label="Nueva Contraseña"
                        aria-autocomplete="false"
                        hint="Minímo 8 caracteres"
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                        color="blue darken-1"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
  
                    <v-col cols="12" sm="12">
                      <validation-provider :rules="{ required: true,min:8, is_not:curp }" vid="confirmation" v-slot="{errors}">
                        <v-text-field
                        v-model="passwordConfirm"
                          :error-messages="errors[0]"
                          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show3 ? 'text' : 'password'"
                          name="input-10-2"
                          label="Confirmar Contraseña"
                          aria-autocomplete="false"
                          class="input-group--focused"
                          @click:append="show3 = !show3"
                          color="blue darken-1"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                </v-row>
              </validation-observer>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-col class="d-flex justify-center" cols="12" sm="12">
              <v-btn
                class="mr-3"
                outlined
                color="blue darken-2"
                small
                @click="closeDialog"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-2" small @click="changePassword">Cambiar contraseña</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
  </template>
  
  <script>
  export default {
    props: ["value"],
    data() {
      return {
        passwordNuevo: null,
        passwordConfirm:null,
        email:null,
        token:null,
        overlay:false,
  
        show1:false,
        show2:false,
        show3:false,
        curp:null

      };
    },
    watch:{
      value(newVal){
        this.passwordNuevo = null
        this.passwordConfirm = null
        this.curp = null;

        this.$refs.formResetPassword?.reset()
      }
    },
    async mounted(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('reset-password-token')
      if(token !==null){
        //enviamos peticion al back para saber si el token sigue activo
        let valid = await this.checkToken(token)
        if(valid){
          this.$emit('input',true)
          this.token = token
        }
      }
  
    },
    methods:{
      closeDialog(){
        this.$emit('input',false)
      },
      async checkToken(token){
        try{
          this.email = null
          let form = new FormData();
          form.append("token",token);
          let resp = await this.$http.post("check-token-password",form).then((res)=>res.data);
  
          if(!resp.status){
  
            return ;  //token invalido
          }
  
          this.email = resp.data.email
          this.curp = resp.data.curp

          //se abre el dialog
          return true
        }catch(err){
          return false
        }
      },
  
      changePassword(){
        this.$refs.formResetPassword.validate().then(async (valid) => {
            if(valid){
              this.closeDialog()
              try{
                let form = new FormData();
                form.append("email",this.email);
                form.append("new_password",this.passwordNuevo);
                form.append("token",this.token);
                this.overlay = true;
                let resp = await this.$http.post("reset-password-lost",form).then((res)=>res.data);
                if(resp.status){
                  setTimeout(() => {
                    this.overlay = false;
                     this.$swal({
                      title: "La contraseña se actualizo correctamente",
                      text: "",
                      icon: "success",
                      button:"Aceptar"
                    }).then(()=>{
                      // this.location
                      location.href = window.location.origin
                    })
                  }, 1500);
                }else {
                  setTimeout(() => {
                    this.overlay = false;
                    this.$swal({
                      title: resp.msg,
                      text: "",
                      icon: "error",
                      button:"Aceptar"
                    }).then(() => this.$emit('input',true));
                  }, 1500);
                }
  
              }catch(error){
                setTimeout(() => {
                    this.overlay = false;
                    this.$swal({
                      title: "Ocurrio un error :(",
                      text: "",
                      icon: "error",
                      button:"Aceptar"
                    });
                  }, 1500);
              }
            }
  
          });
      }
    }
  };
  </script>
  
  <style></style>
  